/* global fetch, document, Rails, FormData */

const csrfToken = document.querySelector("meta[name=csrf-token]").content;

export function ajaxAsync({ type, url, data, signal, onSuccess, onError }) {
  return fetch(url, {
    method: type,
    body: data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    credentials: 'same-origin',
    signal,
  }).then((response) => {
    if (response.ok) {
      response.json().then((responseData) => {
        onSuccess(responseData);
      }).catch(() => {
        onSuccess();
      });
    } else {
      response.json().then((responseData) => {
        onError(responseData);
      }).catch(() => {
        onError();
      });
    }
  }).catch((error) => {
    onError(error);
  });
}

export function getAsync(url, signal = null) {
  return new Promise((resolve, reject) => {
    ajaxAsync({
      type: 'GET', url,
      signal,
      onSuccess: (response) => {
        resolve(response);
      },
      onError: (response) => {
        reject(response);
      },
    });
  });
}

export function deleteAsync(url) {
  return new Promise((resolve, reject) => {
    ajaxAsync({
      type: 'DELETE', url,
      onSuccess: (response) => {
        resolve(response);
      },
      onError: (response) => {
        reject(response);
      },
    });
  });
}

export function postAsync(url, data = {}) {
  return new Promise((resolve, reject) => {
    ajaxAsync({
      type: 'POST',
      url,
      data: JSON.stringify(data),
      onSuccess: (response) => {
        resolve(response);
      },
      onError: (response) => {
        reject(response);
      },
    });
  });
}

export function updateAsync(url, data, method = 'PUT') {
  return new Promise((resolve, reject) => {
    ajaxAsync({
      type: method,
      url,
      data: JSON.stringify(data),
      onSuccess: (response) => {
        resolve(response);
      },
      onError: (response) => {
        reject(response);
      },
    });
  });
}
