import React, { Component } from "react";
import CopyButton from "../shared/CopyButton";

class LinkCopyButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props
    return (
      <CopyButton {...props} onClick={this.handleClick} fading={true}>
        <svg className="wt-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 464H288C296.8 464 304 456.8 304 448V384H352V448C352 483.3 323.3 512 288 512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H128V208H64C55.16 208 48 215.2 48 224V448C48 456.8 55.16 464 64 464zM160 64C160 28.65 188.7 0 224 0H448C483.3 0 512 28.65 512 64V288C512 323.3 483.3 352 448 352H224C188.7 352 160 323.3 160 288V64zM224 304H448C456.8 304 464 296.8 464 288V64C464 55.16 456.8 48 448 48H224C215.2 48 208 55.16 208 64V288C208 296.8 215.2 304 224 304z"/></svg>
      </CopyButton>
    )
  }
}

export default LinkCopyButton;