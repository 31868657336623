// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

require('./js/runtime');
require('./js/vendor');
require('./js/main');
require('./js/sortable');

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));

require('../stylesheets/index');

require('./css/inline.css');
require('./css/modules-fixed.css');

require('@fortawesome/fontawesome-pro/css/all.css');

require('@rails/ujs').start();

require('../initializers/actiontext');
require('../initializers/cookie-check');

// Das Javascript der wegaportal assets funktioniert teilweise nicht mit Turbolinks, da click
// events dem geklickten Element hinzugefügt werden und nicht document oder window. Dies gilt
// für das mobile Menu und den Mehr Informationen Button bei den Prognosen
// require("turbolinks").start()

require('@rails/activestorage').start();
require('channels');

require('../images/wegatech-logo.svg');
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

require('@popperjs/core');
