import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

export class TimeFromNow extends React.Component {
  componentDidMount() {
    this.interval = setInterval(() => {
      this.forceUpdate();
    }, 45000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // http://momentjs.com/docs/#/displaying/fromnow/
  render() {
    let date;
    if (typeof this.props.date === 'string') {
      date = new Date(this.props.date);
    } else {
      date = this.props.date;
    }

    return (
      <span title={moment(date).locale('de').format('LLLL')}>
        {moment(this.props.date).locale('de').fromNow()}
      </span>
    );
  }
}

TimeFromNow.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};
