import Sortable from 'sortablejs';
import { updateAsync } from './rails_helper';

const initSortable = () => {
  document.querySelectorAll('[data-sortable="true"]').forEach((element) => {
    Sortable.create(element, {
      handle: element.dataset.handleSelector,
      dragClass: element.dataset.dragClass,
      ghostClass: element.dataset.ghostClass,
      animation: 200,
      easing: 'cubic-bezier(1, 0, 0, 1)',
      onEnd(event) {
        const data = new FormData();

        data.append('position', event.newIndex + 1);

        updateAsync(event.item.dataset.url, Object.fromEntries(data), 'PATCH').then(() => {
          // sort request successful
        });
      },
    });
  });
};

document.addEventListener('DOMContentLoaded', initSortable);