import React, { Component } from 'react';
import PropTypes from 'prop-types';


class CopyButton extends Component {
  constructor(props) {
    super(props)
    this.state = { fade: false }
  }

  handleClick() {
    if (this.props.fading) {
      this.setState({ fade: true })
    }
    navigator.clipboard.writeText(this.props.textToCopy)
    this.props.afterCopy()
  }

  render() {
    return (
      <div
        onClick={() => this.handleClick()}
        className={this.state.fade ? 'fade' : ''}
        onAnimationEnd={() => this.setState({ fade: false })}
        title={this.props.title}
        style={{cursor: "pointer"}}
      >
        {this.props.children}
      </div>
    )
  }
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  fading: PropTypes.bool,
  title: PropTypes.string,
  afterCopy: PropTypes.func,
}


CopyButton.defaultProps = {
  fading: false,
}

export default CopyButton;