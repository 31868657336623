import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShareButton extends Component {
  handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share({
            url: this.props.url,
            title: this.props.title,
            text: this.props.text,
          })
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };

  render() {
    return (
      <div className="sharer-button" onClick={this.handleSharing}>
        <span className="sharer-button-text">{this.props.children}</span>
      </div>
    );
  }
}

ShareButton.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
}

ShareButton.defaultProps = {
  label: "Mit Freunden teilen",
  url: document.location.href,
  text: "",
  title: "",
}

export default ShareButton;