import React, { Component } from "react";
import ShareOptions from "./ShareOptions";
import PropTypes from 'prop-types';

const shareAvailable = navigator.share;

class CanvassWindow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <ShareOptions
          link={this.props.link}
          shareAvailable={shareAvailable}
        />
      </>
    )
  }
}

CanvassWindow.propTypes = {
  link: PropTypes.string,
  token: PropTypes.string,
}

export default CanvassWindow;