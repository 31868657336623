import React from 'react';
import PropTypes from 'prop-types';

let uploads = [];
let timeout;

export default class UploadStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0, // Gesamter Fortschritt (0-100)
      total: 0, // Anzahl aktueller Uploads
      totalSize: 0, // Gesamtgröße in Byte
      loaded: 0,
      current: 0, // Nummer des aktuellen Uploads
      done: true,
      hidden: true,
    };

    this.fileAddedHandler = this.fileAddedHandler.bind(this);
    this.queueDoneHandler = this.queueDoneHandler.bind(this);
    this.uploadsChangeHandler = this.uploadsChangeHandler.bind(this);

  }

  componentDidMount() {
    this.props.uploader.addListener('onUploadsChanged', this.uploadsChangeHandler);
    this.props.uploader.addListener('onFileAdded', this.fileAddedHandler);
    this.props.uploader.addListener('onQueueDone', this.queueDoneHandler);
  }

  componentWillUnmount() {
    this.props.uploader.removeListener('onUploadsChanged', this.uploadsChangeHandler);
    this.props.uploader.removeListener('onFileAdded', this.fileAddedHandler);
    this.props.uploader.removeListener('onQueueDone', this.queueDoneHandler);
  }

  fileAddedHandler(upload) {
    uploads.push(upload);

    this.setState({
      totalSize: uploads.reduce((memo, upload) => memo + upload.size, 0),
      done: false,
      total: uploads.length,
      hidden: false,
    });

    if (timeout) clearTimeout(timeout);
  }

  queueDoneHandler() {
    timeout = setTimeout(() => {
      this.setState({ hidden: true, progress: 0 });
    }, 2500);
  }

  uploadsChangeHandler() {
    const done = !uploads.some(u => ['uploading', 'waiting'].includes(u.status));

    if (done) {
      this.setState({
        loaded: 0,
        progress: 100,
        current: 0,
        total: 0,
        totalSize: 0,
        done,
      });
      uploads = [];
    } else if (this.state.totalSize) {
      this.setState({
        hidden: false,
        loaded: uploads.reduce((memo, upload) => memo + upload.loaded, 0),
        progress: Math.round((this.state.loaded / this.state.totalSize) * 100),
        current: this.state.total - uploads.filter(u => u.status === 'waiting').length,
      });

      if (timeout) clearTimeout(timeout);
    }
  }

  renderText() {
    if (this.state.done) {
      return (
        <div className="wt-global-progress__content">
          <svg className="wt-icon">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-check" />
          </svg>
          <span style={{ display: 'inline-block', marginLeft: '1em' }}>Upload abgeschlossen.</span>
        </div>
      );
    }

    return (
      <div className="wt-global-progress__content">
        Upload <span className="wt-global-progress__content--count">{this.state.current}</span> von {this.state.total} läuft ...
      </div>
    );
  }

  render() {
    const style = {
      bottom: this.state.hidden ? -67 : 0,
      transition: 'bottom 0.3s ease-in-out',
    };

    return (
      <div className="wt-global-progress" style={style}>
        <progress className="wt-progress-bar" value={this.state.progress} max="100">{this.state.progress}%</progress>
        {this.renderText()}
      </div>
    );
  }
}

UploadStatus.propTypes = {
  uploader: PropTypes.object,
}