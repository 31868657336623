import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';


export default class extends Controller {
  static targets = ['tooltip', 'popover']

  connect() {
    this.tooltipTargets.forEach(el => {
      new bootstrap.Tooltip(el);
    });
    this.popoverTargets.forEach(el => {
      new bootstrap.Popover(el);
    });
  }
}
