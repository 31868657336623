import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShareButton from '../shared/ShareButton';
import CopyButton from '../shared/CopyButton';

class ShareOptions extends Component {
  render() {
    const colStyle = {
      width: this.props.shareAvailable ? '33.333%' : '50%',
      display: 'inline-block',
      textAlign: 'center',
      verticalAlign: 'top',
      lineHeight: '1.5rem',
    };

    const iconSize = '2em';
    const iconStyle = { height: iconSize, width: iconSize, cursor: 'pointer', textDecoration: 'none' };

    const mail_body = "Hallo,%0D%0A" +
      "gerne möchte ich dir für dein neues Energiesystem Wegatech empfehlen.%0D%0A" +
      "Du kannst hier ganz einfach mit wenigen Klicks online dein persönliches Angebot erstellen:%0D%0A" +
      "Zum Angebot > " + this.props.link.replace('=', '%3D') + "%0D%0A" +
      "%0D%0A" +
      "Viele Grüße"

    return (
      <div style={{ width: '100%' }}>
        <div style={colStyle} className="col">
          <CopyButton textToCopy={this.props.link} fading>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={iconStyle}><path d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" /></svg>
            <br />
            <span>Link kopieren</span>
          </CopyButton>
        </div>
        <div style={colStyle} className="col">
          <a className="icon-link" href={`mailto:?subject=Meine Empfehlung für dein neues Energiesystem&body=${mail_body}`}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={iconStyle}><path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" /></svg>
              <br />
              <span style={{ color: 'black' }}>E-Mail versenden</span>
            </div>
          </a>
        </div>
        { this.props.shareAvailable && (
          <div style={colStyle} className="col">
            <ShareButton url={this.props.link}>
              <svg style={iconStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 127.1C448 181 405 223.1 352 223.1C326.1 223.1 302.6 213.8 285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128L448 127.1zM95.1 287.1C113.7 287.1 127.1 273.7 127.1 255.1C127.1 238.3 113.7 223.1 95.1 223.1C78.33 223.1 63.1 238.3 63.1 255.1C63.1 273.7 78.33 287.1 95.1 287.1zM352 95.1C334.3 95.1 320 110.3 320 127.1C320 145.7 334.3 159.1 352 159.1C369.7 159.1 384 145.7 384 127.1C384 110.3 369.7 95.1 352 95.1zM352 416C369.7 416 384 401.7 384 384C384 366.3 369.7 352 352 352C334.3 352 320 366.3 320 384C320 401.7 334.3 416 352 416z" /></svg>
              <br />
              Link teilen
            </ShareButton>
          </div>
        )}
      </div>
    );
  }
}

ShareOptions.propTypes = {
  link: PropTypes.string.isRequired,
  shareAvailable: PropTypes.bool,
};

ShareOptions.defaultProps = {
  shareAvailable: false,
};

export default ShareOptions;
