import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

export class DocumentDropZone extends React.Component {
  constructor (props) {
    super(props);

    this.state = { labelColor: '#fff', pointerEvents: 'auto' };

    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.addDummyFile = this.addDummyFile.bind(this);
    this.redirectToFotosInstructions = this.redirectToFotosInstructions.bind(this);
  }

  onOpenClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.refs.dropzone.open();
  }

  addDummyFile(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDummyFileAdded();
  }

  renderAddButton() {
    return (
      <span className="wt-button wt-button--primary" onClick={this.onOpenClick} style={{ pointerEvents: this.state.pointerEvents }}>
        Dateien auswählen
      </span>
    );
  }

  renderFotoInstructionsButton() {
    if (this.props.fotoInstructionsUrl) {
      return (
        <Fragment>
          <div className="hr-with-text-uploads" style={{ width: '50%' }}><span className="text-for-hr-uploads">ODER</span></div>
          <span className="wt-button wt-button--secondary" onClick={this.redirectToFotosInstructions} style={{ pointerEvents: this.state.pointerEvents }}>
            Zum Fotoleitfaden
          </span>
        </Fragment>

      );
    }

    return null;
  }

  renderAddDummyButton() {
    if (this.props.railsEnv === 'development') {
      return (
        <span className="wt-button wt-button--primary" onClick={(e) => this.props.addDummyFile(e)} style={{ marginTop: '1em', pointerEvents: this.state.pointerEvents }}>
          Dummy Upload
        </span>
      );
    }
    return '';
  }

  redirectToFotosInstructions(e) {
    e.preventDefault();
    e.stopPropagation();
    window.location = this.props.fotoInstructionsUrl;
  }

  handleDragEnter() {
    this.setState({ labelColor: '#f8f7f3', pointerEvents: 'none' });
  }

  handleDragLeave() {
    this.setState({ labelColor: '#fff', pointerEvents: 'auto'  });
  }

  render() {
    return (
      <Dropzone noClick className="darf-nicht-leer-sein" ref="dropzone" onDrop={this.props.onFilesAdded}>
        {({getRootProps, getInputProps}) => (
          <label className="wt-document-upload-form" style={{ backgroundColor: this.state.labelColor }}
            {...getRootProps({
              onDragEnter: this.handleDragEnter,
              onDragLeave: this.handleDragLeave,
              onDrop: this.handleDragLeave,
            })}
          >
            <input {...getInputProps({
              className: "wt-document-upload-form__input",
              type: "file",
              style: { cursor: 'auto' }
            })} />
            <div className="wt-document-upload-form__icon" style={{ pointerEvents: this.state.pointerEvents }}>
              <svg className="wt-icon"  style={{ pointerEvents: this.state.pointerEvents }}>
                <use xlinkHref="#icon-upload"  style={{ pointerEvents: this.state.pointerEvents }} />
              </svg>
            </div>
            <div className="wt-document-upload-form__text" style={{ pointerEvents: this.state.pointerEvents }}>
              Zum Hochladen, Dateien per Drag &amp; Drop hier ablegen.
            </div>
            {this.renderAddButton()}
            {this.renderFotoInstructionsButton()}
            {this.renderAddDummyButton()}
          </label>
        )}
      </Dropzone>
    );
  }
}

DocumentDropZone.propTypes = {
  onFilesAdded: PropTypes.func,
  onDummyFileAdded: PropTypes.func,
  railsEnv: PropTypes.string,
  wegateamUrl: PropTypes.string,
  fotoInstructionsUrl: PropTypes.string,
};

DocumentDropZone.contextTypes = {
  uploader: PropTypes.object,
};
