/**
 * ABlyft
 */

// ABlyft manages the cookie consent on their side.
// This script tag must always be included. ABlyft does not track, when consent is not given.
const ablyftScript = document.createElement('script');

ablyftScript.src = 'https://cdn.ablyft.com/s/23667987.js';

document.head.appendChild(ablyftScript);
