/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import Scroll, { Element } from 'react-scroll';

import PropTypes from 'prop-types';

import { DocumentDropZone } from './DocumentDropZone';
import { DocumentCard } from './DocumentCard';

import Uploader from './uploader';
import UploadStatus from './UploadStatus';

// eslint-disable-next-line prefer-destructuring
const scroller = Scroll.scroller;

export default class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.uploader = new Uploader({
      // Put this in /etc/host to access the other apps from client, too.
      // 127.0.0.1 wegateam
      // 127.0.0.1 wegaportal
      // 127.0.0.1 wegapartner
      // 127.0.0.1 wegaform
      // 127.0.0.1 wegaplan
      // 127.0.0.1 wegapdf
      // 127.0.0.1 wegacalc
      baseUrl: `${this.props.wegateamUrl}/api/v1`,
      token: props.token,
    });

    this.state = {
      uploads: [],
      // Ein paar Testdaten zur Prüfung der Darstellung bei verschiedenen Status
      // uploads: [{
      //   thumb: 'http://lorempixel.com/200/200/sports',
      //   title: 'Foto 3',
      //   createdAt: new Date(),
      //   name: 'dcs-1472565674.jpg',
      //   status: 'waiting',
      //   progress: 0,
      // }, {
      //   thumb: 'http://lorempixel.com/200/200/animals',
      //   title: 'Foto 3',
      //   createdAt: new Date(),
      //   name: 'dcs-1472565674.jpg',
      //   status: 'uploading',
      //   progress: 0.65,
      // }, {
      //   thumb: 'http://lorempixel.com/200/200/fashion',
      //   title: 'Foto 3',
      //   createdAt: new Date(),
      //   name: 'dcs-1472565674.jpg',
      //   status: 'processing',

      //   progress: 0.90,
      // }, {
      //   thumb: 'http://lorempixel.com/200/200/cats',
      //   title: 'Foto 3',
      //   createdAt: new Date(),
      //   name: 'dcs-1472565674.jpg',
      //   status: 'failed',
      // }, {
      //   thumb: 'http://lorempixel.com/200/200/people',
      //   title: 'Foto 3',
      //   createdAt: new Date(),
      //   name: 'dcs-1472565674.jpg',
      //   status: 'stored',
      //   progress: 1,
      // }],
    };

    this.uploadsChangeHandler = this.uploadsChangeHandler.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.removeProjectDoc = this.removeProjectDoc.bind(this);
    this.removeFromDocumentCards = this.removeFromDocumentCards.bind(this);
    this.removeFromUploads = this.removeFromUploads.bind(this);
    this.onScrollClick = this.onScrollClick.bind(this);
  }

  componentDidMount() {
    this.setState({ documentCards: this.props.documentCards });
    this.uploader.addListener('onUploadsChanged', this.uploadsChangeHandler);
  }

  componentWillUnmount() {
    this.uploader.removeListener('onUploadsChanged', this.uploadsChangeHandler);
  }

  onScrollClick() {
    scroller.scrollTo('upload-area', {
      duration: 700,
      delay: 50,
      smooth: true,
      offset: -16,
    });
  }

  removeFromDocumentCards(id) {
    const documentCards = this.state.documentCards.filter(doc => doc.id !== id);

    this.setState({ documentCards });
  }

  removeFromUploads(id) {
    const uploads = this.state.uploads.filter(doc => doc.id !== id);

    this.setState({ uploads });
  }

  removeProjectDoc(id) {
    this.uploader.removeUpload(id, this.removeFromDocumentCards);
  }

  uploadsChangeHandler(uploads) {
    this.setState({ uploads });
  }

  noDocs() {
    return (
      (
        !this.state.documentCards
        || this.state.documentCards.length === 0
      )
      && this.state.uploads.length === 0
    );
  }

  removeUpload(id) {
    this.uploader.removeUpload(id, this.removeFromUploads);

    this.setState({
      uploads: this.state.uploads.filter(doc => doc.id !== id),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderNoDocs() {
    return (
      <div className="ui mobile only transparent fixed center segment" style={{ padding: '0 5px', margin: '1rem' }}>
        {/* <img
          alt="folder icon"
          src="/energyplaner/icon-folder-2-233x186.png"
          className="ui centered mini image" /> */}
        <p className="center">
          Bisher keine Dokumente vorhanden
        </p>
      </div>
    );
  }

  renderCards() {
    let projectDocs;

    if (this.state.documentCards) {
      projectDocs = this.state.documentCards.map((documentCard) => {
        return (
          <div className="wt-document-cards__item" key={`${documentCard.id}`}>
            <DocumentCard
              {...documentCard}
              wegateamUrl={this.props.wegateamUrl}
              onDeleteClick={id => this.removeProjectDoc(id)}
              wegateamContactId={this.props.wegateamContactId} />
          </div>
        );
      });
    } else {
      projectDocs = [];
    }

    const uploads = this.state.uploads.map((upload, i) => {
      return (
        <div className="wt-document-cards__item" key={`${i + (this.state.documentCards || []).length + 1}`}>
          <DocumentCard
            {...upload}
            wegateamUrl={this.props.wegateamUrl}
            onDeleteClick={id => this.removeUpload(id)}
            wegateamContactId={this.props.wegateamContactId} />
        </div>
      );
    });

    // Die aktuellen Uploads oben, die neuesten zuerst, damit man insbesondere auf kleinen
    // Bildschirmen auch den aktuellen Upload sieht, nicht nur den Fortschrittsbalken für
    // alle Uploads.
    return uploads.reverse().concat(projectDocs);
  }

  renderContent() {
    if (this.noDocs()) {
      return this.renderNoDocs();
    }

    return this.renderCards();
  }

  render() {
    return (
      <div>
        <div className="wt-button wt-button--primary" onClick={this.onScrollClick} style={{ cursor: 'pointer', marginBottom: '16px' }}>
          Weitere Dokumente hochladen
        </div>

        <div className="wt-document-cards">
          {this.renderContent()}
        </div>

        <Element name="upload-area">
          <DocumentDropZone
            onFilesAdded={files => this.uploader.addFiles(files, this.props.projectId)}
            onDummyFileAdded={() => this.uploader.addDummyFile()}
            railsEnv={this.props.railsEnv}
            wegateamUrl={this.props.wegateamUrl}
            fotoInstructionsUrl={this.props.fotoInstructionsUrl} />
        </Element>

        <UploadStatus uploader={this.uploader} />
      </div>
    );
  }
}

Documents.propTypes = {
  wegateamContactId: PropTypes.number,
  fotoInstructionsUrl: PropTypes.string,
  wegateamUrl: PropTypes.string,
  token: PropTypes.string,
  documentCards: PropTypes.array,
  railsEnv: PropTypes.string,
  projectId: PropTypes.number,
};
