/* global XMLHttpRequest, FormData */
/* eslint-disable no-console */

/*
  Der Upload-Prozess hat verschiedene Stadien:
  - waiting: Warten auf Beginn des Upload (z.B. wenn noch ein anderer Upload läuft)
  - uploading: Während des Uploads
  - processing: Upload abgeschlossen, Server verarbeitet Dateien
  - stored: Datei wurde erfolgreich gespeichert
  - failed: Upload ist fehlgeschlagen
*/

const dummyImages = [
  'http://lorempixel.com/400/400/people',
  'http://lorempixel.com/400/400/fashion',
  'http://lorempixel.com/400/400/sports',
];

function DummyUpload(options) {
  this.status = 'waiting';
  this.name = 'Dummy File';
  this.contentType = 'image/jpg';
  this.progress = 0;
  this.progressProcessing = 0;
  this.size = 1234567;
  this.loaded = 0;
  this.id = Date.now();
  this.isDummy = true;

  this.start = () => {
    this.interval = setInterval(() => {
      if (this.status === 'waiting') {
        this.status = 'uploading';
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      } else if (this.status === 'uploading') {
        if (this.progress < 1) {
          this.progress = this.progress + 0.05;
          this.loaded = this.progress * this.size;
          if (options.onProgress) options.onProgress();
        } else {
          this.status = 'processing';
        }
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      } else if (this.status === 'processing') {
        if (this.progressProcessing < 1) {
          this.progressProcessing = this.progressProcessing + 0.2;
        } else {
          this.status = 'stored';
          this.thumb = dummyImages[Math.floor(Math.random() * dummyImages.length)];
          if (options.onStatusChanged) options.onStatusChanged(this.status);
        }
      } else {
        clearInterval(this.interval);
      }
    }, 300);
  };
}

function Upload(file, options = {}, projectId) {
  this.file = file;

  this.status = 'waiting';
  this.name = file.name;
  this.contentType = file.type;
  this.progress = 0;
  this.size = file.size;
  this.loaded = 0;
  this.id = null;

  const xhr = new XMLHttpRequest();
  const formData = new FormData();

  this.isUploadedOrHasFailed = () => {
    return ['stored', 'processing', 'failed'].includes(this.status);
  };

  this.start = () => {
    xhr.open('POST', `${options.baseUrl}/projects/${projectId}/attachments`);

    xhr.setRequestHeader('Authorization', `Token token="${options.token}"`);

    formData.append('attachment[document_attributes][file]', file);

    xhr.onload = (event) => {
      if (options.onload) options.onload(event);
      if (options.onStatusChanged) options.onStatusChanged(this.status);
      console.log('Done uploading!');
    };

    xhr.upload.onprogress = (event) => {
      this.loaded = event.loaded;
      this.progress = event.loaded / event.total;
      if (options.onProgress) options.onProgress(event.loaded);
      if (event.loaded / event.total === 1) {
        console.log('Processing...');
        this.status = 'processing';
        if (options.onStatusChanged) options.onStatusChanged(this.status);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.status = 'stored';
          const jsonResponse = JSON.parse(xhr.responseText);
          if (options.onDone) options.onDone(jsonResponse);
          console.log('Done processing!');
          console.log(jsonResponse);
          this.thumb = jsonResponse.data.attachment.thumb;
          this.big = jsonResponse.data.attachment.big;
          this.download = jsonResponse.data.attachment.download;
          this.file = jsonResponse.data.attachment.file;
          this.id = jsonResponse.data.attachment.id;
          // this.original = jsonResponse.original;
          if (options.onStatusChanged) options.onStatusChanged(this.status);
        } else {
          this.status = 'failed';
          if (options.onStatusChanged) options.onStatusChanged(this.status);
          if (options.onerror) options.onerror(xhr.statusText, xhr.responseText);
          console.log('Error', xhr.statusText, xhr.responseText);
        }
      }
    };

    xhr.send(formData);

    this.status = 'uploading';
    console.log('uploading...');
    if (options.onStatusChanged) options.onStatusChanged(this.status);
  };

  return this;
}

export default function (options = {}) {
  let uploads = [];
  let queueDone = true;

  const self = this;

  const eventListeners = {
    onUploadsChanged: [],
    onFileAdded: [],
    onQueueDone: [],
  };

  const runHandlers = (eventName, payload) => {
    eventListeners[eventName].forEach((handler) => {
      handler(payload);
    });
  };

  this.isQueueDone = () => {
    return queueDone;
  };

  this.addListener = (eventName, handler) => {
    eventListeners[eventName].push(handler);
  };

  this.removeListener = (eventName, handler) => {
    const index = eventListeners[eventName].indexOf(handler);

    if (index > -1) {
      eventListeners[eventName].splice(index, 1);
    }
  };

  this.startNext = () => {
    if (!uploads.map(u => u.status).includes('uploading')) {
      const waitingUploads = uploads.filter(u => u.status === 'waiting');
      if (waitingUploads.length) waitingUploads[0].start();
    }
  };

  this.setQueueDone = () => {
    if (!queueDone && !uploads.some(u => ['waiting', 'uploading'].includes(u.status))) {
      queueDone = true;
      runHandlers('onQueueDone');
    }
  };

  this.getUploadDataForProps = () => {
    return uploads.map((upload) => {
      return { ...upload, createdAt: new Date() };
    });
  };

  this.addFiles = (files, projectId) => {
    files.forEach((file) => {
      const upload = new Upload(file, {
        baseUrl: options.baseUrl,
        token: options.token,
        onStatusChanged() {
          runHandlers('onUploadsChanged', self.getUploadDataForProps());
          self.setQueueDone();
          self.startNext();
        },
        onProgress() {
          runHandlers('onUploadsChanged', self.getUploadDataForProps());
        },
      }, projectId);

      uploads.push(upload);
      queueDone = false;
      runHandlers('onFileAdded', upload);

      self.startNext();
    });
  };

  this.addDummyFile = () => {
    const upload = new DummyUpload({
      onStatusChanged() {
        runHandlers('onUploadsChanged', self.getUploadDataForProps());
        self.setQueueDone();
        self.startNext();
      },
      onProgress() {
        runHandlers('onUploadsChanged', self.getUploadDataForProps());
      },
    });

    uploads.push(upload);
    queueDone = false;

    runHandlers('onFileAdded', upload);

    self.startNext();
  };

  this.removeUpload = (id, callback) => {
    const xhr = new XMLHttpRequest();

    xhr.open('DELETE', `${options.baseUrl}/attachments/${id}`);

    xhr.setRequestHeader('Authorization', `Token token="${options.token}"`);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 204) {
        console.log('File deleted');

        if (callback) {
          callback(id);
        }
      }
    };

    xhr.send();

    uploads = uploads.filter(upload => upload.id !== id);
  };

  return this;
}
