/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import moment from 'moment';
import 'moment/locale/de';

import { TimeFromNow } from '../shared/TimeFromNow';

const icons = {
  zip: '/icons/icon-filetype-zip-210x210-@2x.png',
  doc: '/icons/icon-filetype-doc-210x210-@2x.png',
  csv: '/icons/icon-filetype-csv-210x210-@2x.png',
  xls: '/icons/icon-filetype-xls-210x210-@2x.png',
  txt: '/icons/icon-filetype-txt-210x210-@2x.png',
  pdf: '/icons/icon-filetype-pdf-210x210-@2x.png',
  image: '/icons/icon-image-210x210-@x2.png',
  empty: '/icons/icon-document-empty-210x210-@2x.png',
  default: '/icons/icon-document-210x210-@2x.png',
};

// eslint-disable-next-line import/prefer-default-export
export class DocumentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleTrashButtonClick = this.handleTrashButtonClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
  }

  handleDelete(e) {
    this.toggleDeleteModal(e);
    // Seite wieder scrollbar machen
    document.body.classList.remove('wt-overlay-open');
    this.props.onDeleteClick(this.props.id);
  }

  handleCancelButtonClick() {
    this.setState({ deleteModalOpen: false });
    // Seite wieder scrollbar machen
    document.body.classList.remove('wt-overlay-open');
  }

  handleTrashButtonClick() {
    this.setState({ deleteModalOpen: true });
  }

  handleDownload(e) {
    e.preventDefault();
    e.stopPropagation();
    window.top.location = `${this.props.wegateamUrl}${this.props.download}`;
  }

  getIcon() {
    const icon = Object.keys(icons).find(key => (this.props.contentType || '').includes(key));
    return icon ? icons[icon] : icons.default;
  }

  progressPercent() {
    return Math.round(this.props.progress * 100);
  }

  toggleDeleteModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  }

  renderImage() {
    if (this.props.thumb) {
      return (
        <img src={this.props.thumb} alt="" data-object-fit="cover" className="wt-document-card__image" />
      );
    }

    return <img alt={this.props.name} src={this.getIcon()} />;
  }

  renderLoadingIndicator() {
    if (this.props.status !== 'failed') {
      let className = 'wt-loading wt-calculation__animation';
      const size = this.progressPercent();
      if (size) {
        className = `wt-loading wt-calculation__animation wt-loading--${size}`;
      }

      return (
        <div className={className}>
          <div />
          <div />
        </div>
      );
    }

    return null;
  }

  renderStatus() {
    if (this.props.status === 'loading') {
      return (
        <div className="wt-document-card-overlay__message wt-document-card-overlay__message--loading">
          Lädt hoch … {this.progressPercent()}%
        </div>
      );
    }
    if (this.props.status === 'waiting') {
      return (
        <div className="wt-document-card-overlay__message wt-document-card-overlay__message--waiting">
          In Warteschlange …
        </div>
      );
    }
    if (this.props.status === 'processing') {
      return (
        <div className="wt-document-card-overlay__message wt-document-card-overlay__message--processing">
          Wird verarbeitet …
        </div>
      );
    }
    if (this.props.status === 'failed') {
      return (
        <div className="wt-document-card-overlay__message wt-document-card-overlay__message--error">
          Upload fehlgeschlagen
        </div>
      );
    }

    return null;
  }

  renderProgressBar() {
    if (some(['uploading', 'waiting', 'processing', 'failed'], status => status === this.props.status)) {
      let className = 'wt-progress-bar';
      let progressPercent = this.progressPercent();
      if (this.props.status === 'failed') {
        className = 'wt-progress-bar wt-progress-bar--error';
        progressPercent = 0;
      }

      return (
        <progress className={className} value={progressPercent} max="100">{progressPercent}</progress>
      );
    }

    return null;
  }

  renderTimeInfo() {
    if (!some(['uploading', 'waiting', 'processing', 'failed'], status => status === this.props.status)) {
      return (
        <div className="wt-document-card__info__date">
          <TimeFromNow date={this.props.createdAt} />
        </div>
      );
    }

    return null;
  }

  renderReloadOrDownloadButton() {
    if (this.props.status === 'failed') {
      return (
        <button type="button" className="wt-button wt-button--primary" onClick={this.handleDownload}>
          <svg className="wt-icon">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-reload" />
          </svg>
          <span>Wiederholen</span>
        </button>
      );
    }

    let disabled = false;
    if (some(['uploading', 'waiting', 'processing'], status => status === this.props.status)) {
      disabled = true;
    }

    return (
      <button type="button" className="wt-button wt-button--secondary wt-button--icon" disabled={disabled} onClick={this.handleDownload}>
        <svg className="wt-icon">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-download" />
        </svg>
      </button>
    );
  }

  renderTrashButton() {
    let disabled = false;
    let tooltip = 'Löschen';
    if (some(['uploading', 'waiting', 'processing', 'failed'], status => status === this.props.status)) {
      disabled = true;
      tooltip = this.props.status;
    }

    const created = moment(this.props.createdAt); // or whatever start date you have
    const now = moment();

    const days = Math.round(moment.duration(now - created).asDays());

    if (days > 7) {
      disabled = true;
      tooltip = 'Löschen 8 Tage nach Upload deaktiviert';
    }

    return (
      <button
        type="button"
        className="wt-button wt-button--secondary wt-button--icon"
        disabled={disabled}
        onClick={this.handleTrashButtonClick}
        title={tooltip}
      >
        <svg className="wt-icon">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-trash" />
        </svg>
      </button>
    );
  }

  render() {
    let overlayClass = 'wt-document-card-overlay';
    if (some(['uploading', 'waiting', 'processing', 'failed'], status => status === this.props.status)) {
      overlayClass = 'wt-document-card-overlay wt-document-card-overlay--is-visible';
    }

    let deleteModalStyle = { display: 'none' };
    if (this.state.deleteModalOpen) {
      deleteModalStyle = { display: 'block' };
      // Scrollen verhindern, wenn Modal geöffnet:
      document.body.classList.add('wt-overlay-open');
    }

    return (
      <div>
        <div className="wt-document-card">
          <div className="wt-document-card__image-wrap">
            {this.renderImage()}
            <div className={overlayClass}>
              <div className="wt-document-card-overlay__content">
                {this.renderLoadingIndicator()}
                {this.renderStatus()}
              </div>
            </div>
          </div>
          <div className="wt-document-card__name">
            {this.props.name}
          </div>
          <div className="wt-document-card__footer">
            {this.renderProgressBar()}
            <div className="wt-document-card__info">
              <div className="wt-document-card__info__name">
                {this.props.name}
              </div>
              {this.renderTimeInfo()}
            </div>
            <div className="wt-document-card__action">
              {this.renderReloadOrDownloadButton()}
              {this.renderTrashButton()}
            </div>
          </div>
        </div>
        <div className="wt-modal" style={deleteModalStyle}>
          <div className="wt-modal__overlay">
            <div className="wt-o-main-wrap wt-o-main-wrap--l">
              <div className="wt-modal__container" role="dialog" aria-modal="true">
                <div>
                  Möchten Sie das Dokument wirklich löschen?
                </div>
                <div className="wt-modal__footer">
                  <div className="wt-modal__action">
                    <div>
                      <button type="button" className="wt-button wt-button--secondary" onClick={this.handleCancelButtonClick}>
                        <span>Abbrechen</span>
                      </button>
                    </div>
                    <div>
                      <button type="button" className="wt-button wt-button--cta" onClick={this.handleDelete}>
                        <span>Löschen</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DocumentCard.propTypes = {
  thumb: PropTypes.string,
  // big: PropTypes.string,
  download: PropTypes.string,
  name: PropTypes.string,
  // preview: PropTypes.string,
  status: PropTypes.string,
  contentType: PropTypes.string,
  progress: PropTypes.number,
  createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  id: PropTypes.number,
  onDeleteClick: PropTypes.func,
};
